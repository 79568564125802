<div class="row" [formGroup]="searchForm.form">
  <h2 class="fs-5 mb-2">Filtres</h2>
  <div class="row mb-3">
    <label for="localizationWithin" translate>Dans un rayon de: </label>
    <div>
      <div class="pb-2">Entre {{ range[0] }} km et {{ range[1] }} km</div>
      <p-slider
        formControlName="localizationWithin"
        class="w-full"
        [range]="true"
        [min]="0"
        [max]="100"
        [step]="5"
        [animate]="true"
      ></p-slider>
    </div>
  </div>
  <div class="row mb-3">
    <label for="services" translate>Prestations détaillées</label>
    <p-multiSelect
      id="services"
      [style]="{ width: '100%' }"
      [options]="searchForm.services | formatLabels"
      formControlName="services"
      [placeholder]="'Prestations détaillées' | translate"
      display="chip"
      appendTo="body"
      [showClear]="true"
    ></p-multiSelect>
  </div>
  <div *ngFor="let filter of searchForm.filters" class="row mb-3">
    <label [for]="filter.value" translate>{{ filter.label }}</label>
    <p-multiSelect
      [id]="filter.value"
      [style]="{ width: '100%' }"
      [options]="filter.skills | formatLabels"
      [placeholder]="filter.label"
      display="chip"
      appendTo="body"
      [showClear]="true"
      (onChange)="searchForm.changeSkills(filter.value, $event)"
      (onClear)="searchForm.removeSkills(filter.value)"
    ></p-multiSelect>
  </div>
</div>
