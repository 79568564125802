<nav
  class="olga_header navbar navbar-expand-lg bg_purple py-5"
  [ngClass]="{ olga_header_home: isHomeRoute }">
  <div class="container">
    <a class="navbar-brand" href="#">
      <img src="assets/img/olga_logo_white.svg" alt="Olga logo" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
      <ul class="navbar-nav align-items-center">
        <li class="nav-item">
          <a
            translate
            class="nav-link active"
            aria-current="page"
            routerLink="home"
            routerLinkActive="active"
            (click)="closeNavbarToggler()">Accueil</a>
        </li>
        <li class="nav-item">
          <a
            translate
            class="nav-link"
            routerLink="search"
            routerLinkActive="active"
            (click)="closeNavbarToggler()">Recherche</a>
        </li>
        <li class="nav-item">
          <a
            translate
            class="nav-link"
            routerLink="aboutus"
            routerLinkActive="active"
            (click)="closeNavbarToggler()">À propos</a>
        </li>
        <li *ngIf="(user.isConnected | async) === false" class="nav-item">
          <a translate class="btn btn-light" (mouseup)="user.login()">Se connecter</a>
        </li>
        <li *ngIf="user.isConnected | async" class="nav-item">
          <div class="dropdown">
            <a
              class="color_white"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              <img
                src="assets/img/default_avatar.png"
                class="me-2"
                alt="Avatar" />
              <i class="pi pi-angle-down" style="font-size: 1rem"></i>
            </a>
            <ul class="dropdown-menu">
              <li><a translate class="dropdown-item" href="#">Action</a></li>
              <li>
                <a translate class="dropdown-item" href="#">Another action</a>
              </li>
              <li>
                <a translate (mouseup)="user.logout()" class="dropdown-item">Je me déconnecte</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>