import { Component } from '@angular/core';

@Component({
  selector: 'my-space',
  templateUrl: './my-space.component.html',
  styleUrls: ['./my-space.component.scss']
})
export class MySpaceComponent {

}
