import { NgModule } from '@angular/core';
import { FilterByObject, SearchByKeyValue, SearchByValue } from './filter.pipe';
import { PipeArrayReversePipe, PipeArraySortPipe } from './sort.pipe';
import { FormatLabelsPipe, TakePipe } from './format.pipe';

@NgModule({
  declarations: [
    PipeArraySortPipe,
    PipeArrayReversePipe,
    FilterByObject,
    SearchByKeyValue,
    SearchByValue,
    FormatLabelsPipe,
    TakePipe,
  ],
  imports: [
  ],
  exports: [
    PipeArraySortPipe,
    PipeArrayReversePipe,
    FilterByObject,
    SearchByKeyValue,
    SearchByValue,
    FormatLabelsPipe,
    TakePipe,
  ]
})
export class PipesModule { }
