import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Galleria, GalleriaResponsiveOptions } from 'primeng/galleria';
import { VeterinaryClinic } from 'src/app/entities/veterinary_clinic';
import { days } from 'src/app/enums/days';
import { OlgaService } from 'src/app/services/olga.service';

@Component({
	selector: 'page-clinic',
	templateUrl: './page-clinic.component.html',
	styleUrls: ['./page-clinic.component.scss']
})
export class PageClinicComponent implements OnInit, OnDestroy {

	public loaded = false;
	public images = [
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria1.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria1s.jpg',
			alt: 'Description for Image 1',
			title: 'Title 1'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria2.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria2s.jpg',
			alt: 'Description for Image 2',
			title: 'Title 2'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria3.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria3s.jpg',
			alt: 'Description for Image 3',
			title: 'Title 3'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria4.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria4s.jpg',
			alt: 'Description for Image 4',
			title: 'Title 4'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria5.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria5s.jpg',
			alt: 'Description for Image 5',
			title: 'Title 5'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria6.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria6s.jpg',
			alt: 'Description for Image 6',
			title: 'Title 6'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria7.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria7s.jpg',
			alt: 'Description for Image 7',
			title: 'Title 7'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria8.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria8s.jpg',
			alt: 'Description for Image 8',
			title: 'Title 8'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria9.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria9s.jpg',
			alt: 'Description for Image 9',
			title: 'Title 9'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria10s.jpg',
			alt: 'Description for Image 10',
			title: 'Title 10'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria11.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria11s.jpg',
			alt: 'Description for Image 11',
			title: 'Title 11'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria12.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria12s.jpg',
			alt: 'Description for Image 12',
			title: 'Title 12'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria13.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria13s.jpg',
			alt: 'Description for Image 13',
			title: 'Title 13'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria14.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria14s.jpg',
			alt: 'Description for Image 14',
			title: 'Title 14'
		},
		{
			itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria15.jpg',
			thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria15s.jpg',
			alt: 'Description for Image 15',
			title: 'Title 15'
		}
	];

	responsiveOptions: GalleriaResponsiveOptions[] = [
		{
			breakpoint: '1024px',
			numVisible: 5,
		},
		{
			breakpoint: '768px',
			numVisible: 3
		},
		{
			breakpoint: '560px',
			numVisible: 1
		}
	];

	showThumbnails = false;

	fullscreen = false;

	activeIndex = 0;

	onFullScreenListener: any;

	@ViewChild('galleria') galleria: Galleria | undefined;
	public days = days;
	public clinic: VeterinaryClinic = new VeterinaryClinic();
	constructor(
		private cd: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute,
		public olga: OlgaService,
	) {
	}

	async ngOnInit() {
		const clinicName = this.activatedRoute.snapshot.params['name'];
		this.clinic = await this.olga.findClinicByName(clinicName);
		this.bindDocumentListeners();
		this.loaded = true;
	}

	onThumbnailButtonClick() {
		this.showThumbnails = !this.showThumbnails;
	}

	toggleFullScreen() {
		if (this.fullscreen) {
			this.closePreviewFullScreen();
		} else {
			this.openPreviewFullScreen();
		}

		this.cd.detach();
	}

	openPreviewFullScreen() {
		const elem = this.galleria?.element.nativeElement.querySelector('.p-galleria');
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem['mozRequestFullScreen']) {
			/* Firefox */
			elem['mozRequestFullScreen']();
		} else if (elem['webkitRequestFullscreen']) {
			/* Chrome, Safari & Opera */
			elem['webkitRequestFullscreen']();
		} else if (elem['msRequestFullscreen']) {
			/* IE/Edge */
			elem['msRequestFullscreen']();
		}
	}

	onFullScreenChange() {
		this.fullscreen = !this.fullscreen;
		this.cd.detectChanges();
		this.cd.reattach();
	}

	closePreviewFullScreen() {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	}

	bindDocumentListeners() {
		this.onFullScreenListener = this.onFullScreenChange.bind(this);
		document.addEventListener('fullscreenchange', this.onFullScreenListener);
		document.addEventListener('mozfullscreenchange', this.onFullScreenListener);
		document.addEventListener('webkitfullscreenchange', this.onFullScreenListener);
		document.addEventListener('msfullscreenchange', this.onFullScreenListener);
	}

	unbindDocumentListeners() {
		document.removeEventListener('fullscreenchange', this.onFullScreenListener);
		document.removeEventListener('mozfullscreenchange', this.onFullScreenListener);
		document.removeEventListener('webkitfullscreenchange', this.onFullScreenListener);
		document.removeEventListener('msfullscreenchange', this.onFullScreenListener);
		this.onFullScreenListener = null;
	}

	ngOnDestroy() {
		this.unbindDocumentListeners();
	}

	galleriaClass() {
		return `custom-galleria ${this.fullscreen ? 'fullscreen' : ''}`;
	}

	fullScreenIcon() {
		return `pi ${this.fullscreen ? 'pi-window-minimize' : 'pi-window-maximize'}`;
	}


}
