<div class="bg_grey_light">
  <div *ngIf="loaded; else loading" class="container">
    <div class="row olga_clinic_image py-6">
      <div class="col-lg-12">
        <p-galleria
          #galleria
          [value]="images"
          [(activeIndex)]="activeIndex"
          [numVisible]="5"
          [showThumbnails]="showThumbnails"
          [responsiveOptions]="responsiveOptions"
          [showItemNavigators]="true"
          [showItemNavigatorsOnHover]="true"
          [circular]="true"
          [autoPlay]="true"
          [transitionInterval]="3000"
          [containerClass]="galleriaClass()">
          <ng-template pTemplate="item" let-item>
            <img
              [alt]="item.alt"
              [src]="item.itemImageSrc"
              [ngStyle]="{
                width: !fullscreen ? '100%' : '',
                display: !fullscreen ? 'block' : '', 
                'min-height': '500px',
              }" />
          </ng-template>
          <ng-template pTemplate="thumbnail" let-item>
            <div class="grid grid-nogutter justify-content-center">
              <img
                [alt]="item.alt"
                [src]="item.thumbnailImageSrc"
                style="display: block; max-height: 100px" />
            </div>
          </ng-template>
          <ng-template pTemplate="footer" let-item>
            <div class="custom-galleria-footer">
              <button type="button" pButton (click)="onThumbnailButtonClick()">
                <i class="pi pi-list"></i>
              </button>
              <span *ngIf="images" class="title-container">
                <span>{{ activeIndex + 1 }}/{{ images.length }}</span>
                <span class="title">{{ images[activeIndex].title }}</span>
                <span>{{ images[activeIndex].alt }}</span>
              </span>
              <button
                type="button"
                pButton
                (click)="toggleFullScreen()"
                class="fullscreen-button">
                <i [class]="fullScreenIcon()"></i>
              </button>
            </div>
          </ng-template>
        </p-galleria>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <section class="olga_clinic_section">
          <h2 class="fs-4 mb-2">À propos de la clinique</h2>
          <p>
            {{ clinic.description }}
          </p>
        </section>
        <section class="olga_clinic_section">
          <h2 class="fs-4 mb-2">Horaires d'ouverture</h2>
          <ul *ngFor="let day of days" class="list-group list-group-flush">
            <li
              *ngLet="clinic.getOpeningsByDay(day) as openings"
              class="list-group-item d-flex justify-content-between align-items-center px-0 py-1 bg-transparent">
              <b>{{ day }}</b>
              <ng-container *ngIf="openings.length > 0; else closed">
                <span *ngFor="let opening of openings" class="">{{ opening.from }} - {{ opening.to }}
                </span>
              </ng-container>
              <ng-template #closed>
                <span>Closed</span>
              </ng-template>
            </li>
          </ul>
        </section>
        <section class="olga_clinic_section">
          <h2 class="fs-4 mb-2">
            Les prestations ({{ clinic.services.length }})
          </h2>
          <div *ngFor="let category of clinic.categories">
            <!-- <h3>{{ category.label }}</h3> -->
            <div>
              <li *ngFor="let service of clinic.servicesByCategory[category.value]" class="badge rounded-pill">{{
                service.label }}
              </li>
              <!-- <p class="">{{ service.description }}</p> -->

            </div>
          </div>
        </section>
        <section class="olga_clinic_section">
          <h2 class="fs-4 mb-2">
            Autres informations ({{ clinic.skills.length }})
          </h2>
          <div *ngFor="let filter of clinic.filters">
            <h3>{{ filter.label }}</h3>
            <li *ngFor="let skill of clinic.skillsByFilter[filter.value]" class="badge rounded-pill">{{ skill.label }}
            </li>
            <!-- <p class="">{{ skill.description }}</p> -->
          </div>
        </section>
      </div>
      <div class="col-lg-4">
        <div class="card p-2 sticky-top">
          <div class="card-header text-center">
            <h3 class="fs-5">{{ clinic.name }}</h3>
            <p>
              <i class="bi bi-geo-alt color_purple"></i>
              <span class="opacity-50">{{ clinic.address }}</span>
            </p>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush pt-2 pb-4">
              <li
                class="list-group-item d-flex justify-content-between align-items-center py-1 px-0">
                <b translate>Téléphone</b>
                <span class="">{{ clinic.phone }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center py-1 px-0">
                <b translate>Email</b>
                <span class="">{{ clinic.email }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center py-1 px-0">
                <b translate>Site web</b>
                <span class="">
                  <a
                    *ngIf="clinic.website_url"
                    [href]="clinic.website_url"
                    target="_blank">{{ clinic.website }}</a>
                  <span *ngIf="!clinic.website_url">No website</span>
                </span>
              </li>
            </ul>
          </div>
          <div class="card-footer">
            <!-- <a href="#" class="btn btn-secondary mb-2">Demande de devis</a> -->
            <button
              (click)="olga.openAppointmentDialog(clinic.name)"
              class="btn btn-primary w-100">Nous contacter</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <olga-loader></olga-loader>
</ng-template>