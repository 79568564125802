import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Observable, finalize, tap } from 'rxjs';
import { Message, MessageService } from 'primeng/api';

@Injectable()
export class Interceptor implements HttpInterceptor {
	constructor(private message: MessageService) {

	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const started = Date.now();
		const message: Message = {
			key: "http_interceptor"
		};
		let show = false;

		// Récupération du token d'authentification (à remplacer par votre code)
		const token = 'mon-token-d-authentification';

		// Ajout du token dans les entêtes de la requête
		const authReq = request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		});

		return next.handle(authReq)
			.pipe(
				tap({
					// Succeeds when there is a response; ignore other events
					next: () => {
						show = false;
					},
					// Operation failed; error is an HttpErrorResponse
					error: (event) => {
						show = true;
						if (event instanceof HttpErrorResponse) {
							if (event.status === 404) {
								message.severity = "error";
								message.summary = "Not found";
							}
							else if (event.status === 406) {
								message.severity = "warn";
								message.summary = "Not acceptable";
							}
						}
					}
				}),
				// Log when response observable either completes or errors
				finalize(() => {
					const elapsed = Date.now() - started;
					if (show) {
						message.detail = `${request.method} "${request.urlWithParams}" in ${elapsed} ms.`;
						this.message.add(message);
					}
				})
			);
	}
}
