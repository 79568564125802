<home-hero></home-hero>
<section class="olga_how my-15">
  <div class="container">
    <div
      class="row text-center px-9 py-10 bg_grey_light rounded-3 align-items-start position-relative"
    >
      <div class="col-lg-12 mb-5">
        <h2 translate>Comment ça marche ?</h2>
      </div>
      <div class="col-lg-4 mt-4">
        <img
          src="assets/img/img_home_bullet_1.svg"
          class="mb-2"
          alt="Bullet icon"
        />
        <p translate>Sélectionnez les soins requis pour votre animal</p>
      </div>
      <div class="col-lg-4 mt-4">
        <img
          src="assets/img/img_home_bullet_2.svg"
          class="mb-2"
          alt="Bullet icon"
        />
        <p translate>
          Visualisez et comparez les cliniques qui rencontreraient vos attentes
        </p>
      </div>
      <div class="col-lg-4 mt-4">
        <img
          src="assets/img/img_home_bullet_3.svg"
          class="mb-2"
          alt="Bullet icon"
        />
        <p translate>Demandez un devis et/ou un rendez-vous</p>
      </div>
    </div>
  </div>
</section>
<section
  class="olga_testimonial bg_pink olga_rounded_bottom_right_4 mt-5 mb-5 py-10"
>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 offset-lg-1 mb-4 mb-lg-0">
        <blockquote>
          <p class="fs-3 pb-2" [translate]="'blockquote'"></p>
          <div>
            <strong class="fs-5">Mathieu</strong> <br />
            <cite translate>Propriétaire de Max</cite>
          </div>
        </blockquote>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <img
          src="assets/img/img_home_testimonial.png"
          class="mb-3 img img-fluid"
          alt="Mathieu, propriétaire de Max"
        />
      </div>
    </div>
  </div>
</section>
<section class="olga_cta my-15">
  <div class="container">
    <div class="row text-center">
      <div class="col-lg-12">
        <h2 translate>Vous souhaitez voir votre clinique sur Olga ?</h2>
        <button
          (click)="olga.openJoinUsDialog()"
          translate
          class="btn btn-primary mt-4"
        >
          Je vous rejoins sur la plateforme
        </button>
      </div>
    </div>
  </div>
</section>
