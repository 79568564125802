import { daysType } from "../enums/days";
import { LabelValueCategories, LabelValueFilters, LabelValueServices, LabelValueSkills } from "../services/interfaces";
import { Entity } from "./entity";
import { opening } from "./opening";

export class VeterinaryClinic extends Entity {
    public override tableName = "veterinary_clinics";
    public name = "There is no name";
    public description = "There is no description";
    public address = "";
    public lat = 0;
    public lng = 0;
    public score = 0;
    public phone = "";
    public email = "";
    public website = "";
    public website_url: string | null = null;
    public categories: LabelValueCategories[] = [];
    public filters: LabelValueFilters[] = [];
    public skills: LabelValueSkills[] = [];
    public services: LabelValueServices[] = [];
    public servicesByCategory: any = {};
    public skillsByFilter: any = {};
    public openings: opening[] = [];
    constructor(o?: object) {
        super();
        Object.assign(this, o);
        this.checkData();
        this.buildLists();
    }

    checkData() {
        this.address = this.address?.length > 0 ? this.address : "There is no address";
        this.phone = this.phone?.length > 0 ? this.phone : "No phone";
        this.email = this.email?.length > 0 ? this.email : "No email";
        this.website = this.website?.length > 0 ? this.email : "No website";
        this.website_url = this.website && this.website.startsWith("http") ? this.website : null;
    }

    buildLists() {
        let temp: any = {};
        this.services.forEach((service: LabelValueServices) => {
            temp[service.category.value] = service.category;
            this.servicesByCategory[service.category.value] = this.servicesByCategory[service.category.value] || [];
            this.servicesByCategory[service.category.value].push(service);
        })
        this.categories = Object.values(temp);
        temp = {};
        this.skills.forEach((skill: LabelValueSkills) => {
            temp[skill.filter.value] = skill.filter;
            this.skillsByFilter[skill.filter.value] = this.skillsByFilter[skill.filter.value] || [];
            this.skillsByFilter[skill.filter.value].push(skill);
        })
        this.filters = Object.values(temp);
    }

    getOpeningsByDay(day: daysType) {
        return this.openings.filter((opening: opening) => opening.day === day);
    }
}