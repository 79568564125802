import { Entity } from "./entity";

export class User extends Entity {
    public override tableName = "users";
    public firstname = "";
    public lastname = "";
    public email = "";
    public phone = "";
    constructor(o?: object) {
        super();
        Object.assign(this, o);
    }
}