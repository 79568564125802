import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageHomeComponent } from './components/page-home/page-home.component';
import { PageSearchComponent } from './components/page-search/page-search.component';
import { MySpaceComponent } from './components/page-my-space/my-space.component';
import { JoinUsComponent } from './components/page-join-us/join-us.component';
import { PageClinicComponent } from './components/page-clinic/page-clinic.component';

const routes: Routes = [
  { path: 'home', component: PageHomeComponent },
  { path: 'search', component: PageSearchComponent },
  { path: 'myspace', component: MySpaceComponent },
  { path: 'joinus', component: JoinUsComponent },
  { path: 'vet/:name', component: PageClinicComponent },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
