import { Pipe, PipeTransform } from '@angular/core';
import { LabelValue } from '../services/interfaces';

@Pipe({ name: 'formatLabels' })
export class FormatLabelsPipe implements PipeTransform {
    transform(array: LabelValue[]): LabelValue[] {
        return array.map((arr) => {
            arr.label = arr.label.charAt(0).toUpperCase() + arr.label.slice(1);
            return arr;
        })
    }
}

@Pipe({ name: 'take' })
export class TakePipe implements PipeTransform {
    transform(array: Array<any> | Set<any>, take = 5): Array<any> {
        const _array = Array.from(array);
        return _array.slice(0, take);
    }
}