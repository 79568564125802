import { Pipe, PipeTransform } from "@angular/core";

export const SuperSorter = (fields: any[]) => (a: { [x: string]: number; }, b: { [x: string]: number; }) => fields.map(o => {
  let dir = 1;
  if (o[0] === '-') { dir = -1; o = o.substring(1); }
  return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

@Pipe({ name: "sort" })
export class PipeArraySortPipe implements PipeTransform {
    /**
     * Trie un tableau d'objets sur un ou plusieurs champs
     * @param {String|Array<String>} field.s Les champs sur lesquels on veut trier. Trie sur le premier, puis le second,... (Mettre {-} pour DESC devant le nom du champ)
     * @example | sort:'name'
     * @example | sort:['-name', 'value']
     * @returns {Array<Object>} Retourne le tableau trié
     */
  transform(array: Array<any>, fields: string | Array<string>): any[] {
    const _fields = Array.isArray(fields) && fields?.length>0 ? fields : typeof fields === "string" ? [fields] : []
    if (Array.isArray(array) && array.length > 0) {
      return array.sort(SuperSorter(_fields));
    }
    else return array;
  }
}

@Pipe({ name: 'reverse' })
export class PipeArrayReversePipe implements PipeTransform {
    /**
     * Renverse l'ordre du tableau
     * @example | reverse
     */
  transform(array: any[]) {
    return array.slice().reverse();
  }
}
