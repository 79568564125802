<div class="olga_footer text-center bg_grey_light rounded-top-4 py-12">
  <div class="container">
    <img src="assets/img/olga_logo_color.svg" alt="Olga logo" />
    <nav class="navbar navbar-expand-lg justify-content-center mt-3 mb-3">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a translate class="nav-link" href="#">Accueil</a>
        </li>
        <li class="nav-item">
          <a translate class="nav-link" href="#">Recherche</a>
        </li>
        <li class="nav-item">
          <a translate class="nav-link" href="#">À propos</a>
        </li>
        <li class="nav-item">
          <a translate class="nav-link" href="#">Contact</a>
        </li>
      </ul>
    </nav>
    <ul class="d-flex flex-row justify-content-center list-unstyled">
      <li class="me-3">
        <a class="" href="#">
          <img src="assets/img/img_social_instagram.svg" alt="Instagram icon" />
        </a>
      </li>
      <li class="me-3">
        <a class="" href="#">
          <img src="assets/img/img_social_youtube.svg" alt="Youtube icon" />
        </a>
      </li>
      <li class="">
        <a class="" href="#">
          <img src="assets/img/img_social_facebook.svg" alt="Facebook icon" />
        </a>
      </li>
    </ul>
  </div>
</div>