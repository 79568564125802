<div class="card h-100">
  <div class="card-header">
    <img
      src="assets/img/img_default_clinique.jpg"
      class="card-img-top"
      alt="Default clinic" />
    <h3 class="card-title">{{ clinic.name }}</h3>
    <p>
      <i class="bi bi-geo-alt color_purple"></i>
      <span class="opacity-50">{{ clinic.address }}</span>
    </p>
  </div>
  <div class="card-body">
    <div *ngFor="let filter of filters | take : 5">
      <h4 class="text-uppercase display-6 fw-bold">{{ filtersByName[filter].label }}</h4>
      <ul class="list-unstyled olga_advantages_list">
        <li class="badge rounded-pill" *ngFor="let skill of getSkillsOfFilter(filter) | take : 5">
          {{ skill.label }}
        </li>
      </ul>
    </div>
  </div>
  <div class="card-footer">
    <a routerLink="/vet/{{ clinic.name }}" class="btn btn-primary mb-2">Plus d’infos sur la clinique</a>
    <button (click)="olga.openAppointmentDialog(clinic.name)" class="btn btn-secondary w-100">Nous contacter</button>
  </div>
</div>