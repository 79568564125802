import { Component, Input } from '@angular/core';
import { VeterinaryClinic } from 'src/app/entities/veterinary_clinic';
import { LabelValueSkills } from 'src/app/services/interfaces';
import { OlgaService } from 'src/app/services/olga.service';

@Component({
	selector: 'card-clinic',
	templateUrl: './card-clinic.component.html',
	styleUrls: ['./card-clinic.component.scss']
})
export class CardClinicComponent {
	public filters: string[] = [];
	public skillsByFilter: any = {};
	public filtersByName: any = {};
	public skills: LabelValueSkills[] = [];
	public clinic: any = {};
	@Input() set data(clinic: VeterinaryClinic) {
		this.clinic = clinic;
		this.skills = clinic.skills || [];
		this.skills.forEach((skill: LabelValueSkills) => {
			if (skill.filter) {
				this.filtersByName[skill.filter.value] = skill.filter;
				this.skillsByFilter[skill.filter.value] = this.skillsByFilter[skill.filter.value] || [];
				this.skillsByFilter[skill.filter.value].push(skill);
			}
		});
		this.filters = Object.keys(this.filtersByName);
	}

	constructor(
		public olga: OlgaService,
	) {

	}

	getSkillsOfFilter(filterName: string) {
		return this.skillsByFilter[filterName];
	}

}
