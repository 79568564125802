import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
	constructor(
		public router: Router,
		public user: UserService,
	) {

	}

	public get isHomeRoute() {
		return this.router.url === "/" || this.router.url === "/home";
	}

	public closeNavbarToggler() {
		const navbarToggler = document.querySelector(".navbar-toggler:not(.collapsed)");
		if (navbarToggler instanceof HTMLElement) {
			navbarToggler.click();
		}
	}

}
