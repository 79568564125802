import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	constructor(
		private translate: TranslateService,
		private message: MessageService
	) { }

	error(message:Message) {
		message.summary = message.summary || this.translate.instant("Error");
		message.severity = "error";
		return this.send(message);
	}

	send(message: Message) {
		message.summary = this.translate.instant(String(message.summary));
		message.detail = this.translate.instant(String(message.detail));
		message.key = 'general';
		this.message.add(message);
	}
}
