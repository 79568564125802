import { Component, OnInit } from '@angular/core';
import { SearchFormService } from 'src/app/services/search-form.service';

@Component({
  selector: 'page-search',
  templateUrl: './page-search.component.html',
  styleUrls: ['./page-search.component.scss'],
})
export class PageSearchComponent implements OnInit {
  sidebarVisible = false;
  clinics = [];
  constructor(public searchForm: SearchFormService) { }

  ngOnInit(): void {
    this.searchForm.searchClinics();
  }

}
