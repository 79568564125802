import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { prefixes } from 'src/app/enums/prefixes';

@Component({
	selector: 'appointment',
	templateUrl: './appointment.component.html',
	styleUrls: ['./appointment.component.scss'],
})
export class AppointmentComponent {
	prefixes = prefixes;
	public clinicName = 'OlgaVet';
	public text = `Bonjour,\n
Je souhaiterais obtenir un devis pour la prestation suivante :\n
Me recontacter de preference : par telephone/email\n
Remarques éventuelles :\n
Cordialement,`;

	form = new FormGroup({
		animalName: new FormControl('', {
			updateOn: 'blur',
			validators: [Validators.required, Validators.minLength(2)],
		}),
		animalAge: new FormControl('', {
			updateOn: 'blur',
			validators: [Validators.required, Validators.minLength(1)],
		}),
		animalType: new FormControl('', {
			updateOn: 'blur',
			validators: [Validators.required, Validators.minLength(3)],
		}),
		firstName: new FormControl('', {
			updateOn: 'blur',
			validators: [Validators.required, Validators.minLength(4)],
		}),
		lastName: new FormControl('', {
			updateOn: 'blur',
			validators: [Validators.required, Validators.minLength(4)],
		}),
		prefix: new FormControl('', {
			updateOn: 'blur',
			validators: [Validators.required, Validators.minLength(2)],
		}),
		phone: new FormControl('', {
			updateOn: 'blur',
			validators: [Validators.required, Validators.minLength(4)],
		}),
		object: new FormControl('', {
			updateOn: 'blur',
			validators: [],
		}),
		email: new FormControl('', {
			updateOn: 'blur',
			validators: [Validators.email, Validators.required],
		}),
		message: new FormControl(this.text, {
			updateOn: 'blur',
			validators: [],
		}),
	});

	constructor(private translate: TranslateService,) { }

	uploadedFiles: any[] = [];

	onUpload(event: any) {
		for (const file of event.files) {
			this.uploadedFiles.push(file);
		}
	}
}
