import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OlgaPrimengModule } from './modules/primeng.module';
import { OlgaTranslateModule } from './modules/translate.module';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { OlgaService } from './services/olga.service';
import { OlgaComponentsModule } from './modules/components.module';
import { Interceptor } from './services/http.interceptor';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { PipesModule } from './pipes/pipes.module';
import { NgLetModule } from 'ng-let';

export let AppInjector: Injector;
@NgModule({
	declarations: [AppComponent],
	imports: [
		CommonModule,
		RecaptchaV3Module,
		AppRoutingModule,
		HttpClientModule,
		OlgaPrimengModule,
		OlgaTranslateModule,
		OlgaComponentsModule,
		PipesModule,
		NgLetModule,
		AuthModule.forRoot({
			domain: 'zengic.eu.auth0.com',
			clientId: 'n7CqOfWquDghirM50UBoEUeXvuiNZLBm',
			authorizationParams: {
				redirect_uri: `${environment.BASE_URL}/myspace`,
			},
		}),
	],
	providers: [
		OlgaService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Interceptor,
			multi: true
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.RECAPTCHA,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private injector: Injector) {
		AppInjector = this.injector;
	}
}
