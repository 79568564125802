import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OlgaService } from 'src/app/services/olga.service';
import { SearchFormService } from 'src/app/services/search-form.service';

@Component({
  selector: 'form-search',
  templateUrl: './form-search.component.html',
  styleUrls: ['./form-search.component.scss']
})
export class FormSearchComponent {
  public isLoading = false;
  constructor(
    public olga: OlgaService,
    public router: Router,
    public searchForm: SearchFormService) {

  }

  goTo() {
    if (this.router.url !== "/search") {
      this.router.navigateByUrl('/search');
    }
    else {
      this.searchForm.searchClinics();
    }
  }

}
