import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OlgaService } from './services/olga.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'olga-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'olga';
  constructor(
    private translate: TranslateService,
    private olga: OlgaService,
    private titleService: Title,
    private metaService: Meta
  ) {
    titleService.setTitle('OLGA : Veterinary services');
    metaService.updateTag({ name: 'description', content: `description` });

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('fr');

    olga.hello();
  }

  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }
}
