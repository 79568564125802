import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OlgaService } from './olga.service';
import { LabelValue, LabelValueCategories, LabelValueFilters, LabelValueServices } from './interfaces';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectChangeEvent, MultiSelectRemoveEvent } from 'primeng/multiselect';

@Injectable({
	providedIn: 'root'
})
export class SearchFormService {
	public isLoading = true;
	public form = new FormGroup({
		animalType: new FormControl('', {
			updateOn: 'change',
			validators: [],
		}),
		// Prestations
		services: new FormControl('', {
			updateOn: 'change',
			validators: [],
		}),
		// Categorie de prestations
		categories: new FormControl('', {
			updateOn: 'change',
			validators: [],
		}),
		skills: new FormControl('', {
			updateOn: 'change',
			validators: [],
		}),
		localization: new FormControl('', {
			updateOn: 'blur',
			validators: [],
		}),
		localizationWithin: new FormControl([10, 50], {
			updateOn: 'change',
			validators: [],
		}),
		price: new FormControl('', {
			updateOn: 'change',
			validators: [],
		}),
		timelaps: new FormControl('', {
			updateOn: 'change',
			validators: [],
		}),
	});

	public postalCodes: LabelValue[] = [];
	public animalTypes: LabelValue[] = [];
	public categories: LabelValueCategories[] = [];
	public filters: LabelValueFilters[] = [];
	public services: LabelValueServices[] = [];

	public clinics: any[] = [];

	constructor(
		private olga: OlgaService,
		private translate: TranslateService,
	) {
		this.translate.onLangChange.subscribe(() => {
			this.loadEnums();
		});
		this.form.valueChanges.subscribe(() => {
			this.selectServices();
			this.searchClinics();
		});
	}

	async loadEnums(): Promise<void> {
		const enums = await lastValueFrom(this.olga.getEnums(this.translate.currentLang));
		for (const [key, data] of Object.entries(enums)) {
			switch (key) {
				case "animalTypes":
					this.animalTypes = data;
					break;
				case "categories":
					this.categories = data;
					break;
				case "filters":
					this.filters = data;
					break;
			}
		}
	}

	async searchPostalCodes(event: any) {
		const search = event.query;
		if (search.length >= 3) {
			this.postalCodes = await this.olga.postalCodes(search);
		}
	}

	async searchClinics() {
		if (this.form.valid) {
			const value: any = this.form.value;
			const data: any = {};
			if (Array.isArray(value.services) && value.services.length > 0) {
				data.services = value.services;
			}
			if (Array.isArray(value.categories) && value.categories.length > 0) {
				data.categories = value.categories;
			}
			if (Array.isArray(value.skills) && value.skills.length > 0) {
				data.skills = value.skills;
			}
			if (Array.isArray(value.filters) && value.filters.length > 0) {
				data.filters = value.filters;
			}
			if (value.localization?.label?.length > 0) {
				data.localization = value.localization.label;
			}
			if (data.localization && value.localizationWithin?.length > 0) {
				data.localizationWithin = value.localizationWithin;
			}
			if (value.animalType?.length > 0) {
				data.animalType = value.animalType;
			}
			try {
				this.clinics = await this.olga.searchClinics(data);
			} catch { /* empty */ }
		}
		this.isLoading = false;
	}

	hasValue(controlName: string) {
		const control: FormControl = this.form.get(controlName) as FormControl;
		const check = !!control.value;
		return check;
	}

	selectServices() {
		const categoriesControl = this.form.get("categories") as FormControl;
		const services: any[] = [];
		this.categories.filter((category) => categoriesControl.value.includes(category.value)).forEach((category) => {
			services.push(...category.services);
		})
		this.services = services;
	}

	private selectedSkillsByFilter: { [key: string]: Array<string> } = {};
	changeSkills(filter: string, event: MultiSelectChangeEvent) {
		this.selectedSkillsByFilter[filter] = event.value;
		this.patchSkills();
	}

	removeSkills(filter: string) {
		this.selectedSkillsByFilter[filter] = [];
		this.patchSkills();
	}

	private patchSkills() {
		const temp: string[] = [];
		Object.values(this.selectedSkillsByFilter).forEach((val: Array<string>) => {
			temp.push(...val);
		})
		const control: FormControl = this.form.get("skills") as FormControl;
		control.patchValue(temp);
	}

}
