import { Component } from '@angular/core';
import { OlgaService } from 'src/app/services/olga.service';

@Component({
  selector: 'page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent {
  constructor(
    public olga: OlgaService,
  ) {

  }
}
