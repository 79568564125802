<div class="row p-3 align-items-end" [formGroup]="searchForm.form">
  <div class="col-lg-3 mb-3 mb-lg-0 ps-lg-0">
    <label for="animalType" translate>Animal</label>
    <p-dropdown
      id="animalType"
      [style]="{ width: '100%' }"
      [options]="searchForm.animalTypes | sort : 'label'"
      formControlName="animalType"
      [placeholder]="'Animal' | translate"
      display="chip"
      [showClear]="true"
    ></p-dropdown>
  </div>
  <div class="col-lg-3 mb-3 mb-lg-0">
    <label for="categories" translate>Prestations</label>
    <p-multiSelect
      id="categories"
      [style]="{ width: '100%' }"
      [options]="searchForm.categories | sort : 'label'"
      formControlName="categories"
      [placeholder]="'Prestations' | translate"
      display="chip"
      [showClear]="true"
    ></p-multiSelect>
  </div>
  <div class="col-lg-3 mb-3 mb-lg-0">
    <label for="localization" translate>Localisation</label>
    <p-autoComplete
      [style]="{ width: '100%', display: 'grid' }"
      formControlName="localization"
      [suggestions]="searchForm.postalCodes"
      (completeMethod)="searchForm.searchPostalCodes($event)"
      [placeholder]="'Localisation' | translate"
      [minLength]="3"
      [delay]="400"
    ></p-autoComplete>
  </div>
  <div class="col-lg-3 mb-3 mb-lg-0 pe-lg-0">
    <button class="btn btn-primary w-100" (click)="goTo()" pButton translate>
      Rechercher
    </button>
  </div>
</div>
