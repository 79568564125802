import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { VeterinaryClinic } from '../entities/veterinary_clinic';
import { DialogService } from 'primeng/dynamicdialog';
import { AppointmentComponent } from '../components/page-appointment/appointment.component';
import { JoinUsComponent } from '../components/page-join-us/join-us.component';

@Injectable({
  providedIn: 'root',
})
export class OlgaService {
  debug = environment.DEBUG;
  public running = false;
  public loaded = new Subject();

  constructor(
    private http: HttpClient,
    private dialog: DialogService,
  ) {
    this.loaded.subscribe((value) => {
      this.running = !value;
    })
  }

  openAppointmentDialog(clinicName: string) {
    this.dialog.open(AppointmentComponent, {
      header: `Contacter ${clinicName}`,
    });
  }

  openJoinUsDialog() {
    this.dialog.open(JoinUsComponent, {
      header: `Join us`,
    });
  }

  getEnums(language: string): Observable<[]> {
    return this.http.get<[]>(`${environment.API_URL}/enums/${language}`);
  }

  async hello(): Promise<any> {
    if (environment.DEBUG) {
      const result: any = await lastValueFrom(this.http.get<boolean>(`${environment.API_URL}`));
      if (result.text !== "OlgaBot") {
        alert("Impossible to call OlgaBot");
        return result;
      }
    }
  }

  async postalCodes(search: string) {
    const result: string[][] = await lastValueFrom(this.http.get<[]>(`${environment.API_URL}/postalcodes/${search}`));
    return result.map((res) => {
      return {
        label: `${res[2].toUpperCase()}${res[0]} - ${res[1].toUpperCase()}`,
        value: res[2] + res[0],
      }
    })
  }

  async sendJoinUs(form: FormGroup): Promise<{ message: string }> {
    if (form.valid) {
      return lastValueFrom(this.http.post<{ message: string }>(`${environment.API_URL}/joinus`, form.value));
    }
    return { message: `<div class="text-center text-danger mb-3"><b>The form is not valid</b></div>` };
  }

  async searchClinics(data: any): Promise<any[]> {
    return lastValueFrom(this.http.post<any[]>(`${environment.API_URL}/veterinary_clinics/search`, data)) || [];
  }

  async findClinicByName(name: string): Promise<VeterinaryClinic> {
    const clinic = await lastValueFrom(this.http.post<any>(`${environment.API_URL}/veterinary_clinics/findbyname`, { name })) || {};
    return new VeterinaryClinic(clinic);
  }

}
