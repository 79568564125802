<section class="olga_home_hero rounded-bottom-4 bg_purple position-relative">
  <div class="container position-relative">
    <div class="row">
      <div class="col-lg-12">
        <div class="olga_sticky_img_right">
          <div class="container-fluid">
            <div class="row align-items-start">
              <div class="col-lg-5 color_white pt-20">
                <h1 class="fs-2" [innerHTML]="'home-hero-h1' | translate"></h1>
                <h2
                  class="lead mt-4"
                  [innerHTML]="'home-hero-h2' | translate"></h2>
              </div>
              <div class="col-lg-6 offset-lg-1 pe-0 position-relative">
                <img
                  src="assets/img/img_home_hero.jpg"
                  class="olga_cover_image olga_object_fit_cover olga_rounded_top_left_4 olga_rounded_bottom_right_4 w-100"
                  alt="Olga dog" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row olga_home_form_search position-absolute">
      <div class="col-lg-10 offset-lg-1 bg-white rounded-2">
        <form-search></form-search>
      </div>
    </div>
  </div>
</section>