import { Pipe, PipeTransform } from '@angular/core';

function validateFilter(obj: any) {
	const temp: any = {};
	for (const [key, val] of Object.entries(obj)) {
		let check = false;
		if (
			typeof val === 'string' &&
			val !== undefined &&
			val !== null &&
			val.length > 0
		) {
			check = true;
		} else if (typeof val === 'number' && val !== undefined && val !== null) {
			check = true;
		} else if (Array.isArray(val) && val.length > 0) {
			check = true;
		} else if (typeof val === 'boolean' && val !== undefined && val !== null) {
			check = true;
		}
		if (check) temp[key] = val;
	}
	return temp;
}

function compareNumbers(val: number, compare: number) {
	return +val === +compare;
}
function compareStrings(val: string, compare: string) {
	const _val = String(val).toLowerCase().replace(/\s/g, '');
	const _compare = String(compare).toLowerCase().replace(/\s/g, '');
	return _val.search(_compare) >= 0;
}
function compareStringInArray(array: string[], compare: string) {
	return array.some((value) => compareStrings(value, compare));
}
// function compareNumberInArray(array: number[], compare: number) {
//   return array.some((value) => compareNumbers(value, compare));
// }

/**
 * @param val est la valeur qu'on compare
 * @param search est la valeur de comparaison avec val, soit un nombre, string, array, etc...
 */
function compareIt(
	val: string | number | Date,
	search: string | any[]
): boolean {
	if (Array.isArray(search) && search.length > 0) {
		if (
			search.length === 2 &&
			search[0] instanceof Date &&
			search[1] instanceof Date
		) {
			const date: any = new Date(val);
			const firstDate = new Date(search[0]);
			const lastDate = new Date(search[1]);
			if (firstDate < date && lastDate > date) {
				return true;
			}
		}
		// UNIQUEMENT SI C EST UN RANGE
		else if (
			search.length === 2 &&
			typeof search[0] === 'number' &&
			typeof search[1] === 'number'
		) {
			return +val >= search[0] && +val <= search[1];
		}
		// SI ON COMPARE AVEC PLUSIEURS STRINGS OR NUMBERS
		else if (Array.isArray(val)) {
			return val.some((_value: any) => {
				return search.some((_search: any) => {
					return compareIt(_value, _search);
				});
			});
		} else if (typeof val === 'number') {
			return search.some((value) => compareNumbers(+val, +value));
		} else if (typeof val === 'string') {
			return search.some((value) => compareStrings(val, value));
		}
	} else if (typeof search === 'number') {
		return compareNumbers(+val, +search);
	} else if (typeof search === 'string') {
		return compareStrings(String(val), search);
	} else if (typeof search === 'boolean' && typeof val === 'boolean') {
		return search === val;
	}
	return false;
}

@Pipe({ name: 'filter' })
export class FilterByObject implements PipeTransform {
	/**
	 * @example | filter:{"name": "JD11"}:"AND"
	 * @param array Array of records
	 * @param comparator Object comparator. Could also be a unique string
	 * @param opt AND|OR
	 * @returns any[]
	 */
	transform(array: any[], comparator: any, opt: 'AND' | 'OR' = 'AND'): any[] {
		// opt peut etre égale à AND ou à OR

		comparator = validateFilter(comparator);

		if (Array.isArray(array) && array.length > 0) {
			array.forEach((item: any) => {
				item.__match = true;
				const keys = Object.keys(comparator);
				for (let i = 0; i < keys.length; i++) {
					const key = keys[i];
					const search = comparator[key];
					if (opt === 'AND')
						item.__match = item.__match && compareIt(item[key], search);
					if (opt === 'OR') {
						item.__match = compareIt(item[key], search);
						if (item.__match) break;
					}
				}
			});
			return array.filter((x) => x.__match);
		} else return array;
	}
}

@Pipe({ name: 'searchByKey' })
export class SearchByKeyValue implements PipeTransform {
	/**
	 * @description La différence avec filter est que il fait une recherche sur le contenu d'un string. (ce n'est pas ===)
	 * @example | searchByKey:key:value
	 * @param array
	 * @param key
	 * @param value
	 * @returns
	 */
	transform(array: any[], key: string, value: string): any[] {
		if (
			typeof value === 'string' &&
			(value.length == 0 || !value || value == null)
		)
			return array;

		if (!key || !value) {
			throw 'Il faut une key => searchByKey:key:value';
		}

		if (Array.isArray(array) && array.length > 0) {
			const txt = String(value).toLowerCase().replace(/\s/g, '');
			if (txt.length > 0) {
				return array.filter((item) => {
					const value = item[key];
					if (typeof value === 'string') {
						return compareStrings(value, txt);
					} else if (Array.isArray(value) && value.length > 0) {
						return compareStringInArray(value, txt);
					}
					return false;
				});
			}
		}
		return array;
	}
}

@Pipe({ name: 'search' })
export class SearchByValue implements PipeTransform {
	/**
	 * On fait une recherche sur le contenu string de tout l'item de l'array.
	 * On peut exclure des clés sur lequels on ne veut pas faire la recherche.
	 * @param array
	 * @param value
	 * @param excludedKeys
	 * @example | search:compare:[excludedKeys]
	 * @returns
	 */
	transform(
		array: any[],
		compare: string,
		excludedKeys: Array<string> = []
	): any[] {
		if (!compare || compare.length == 0 || compare == null) return array;
		if (Array.isArray(array) && array.length > 0 && compare.length > 0) {
			return array.filter((items) => {
				return Object.entries(items).some(([key, value]) => {
					const testKey = excludedKeys.indexOf(key) >= 0;
					if (!testKey) {
						if (typeof value === 'string') {
							return compareStrings(value, compare);
						} else if (Array.isArray(value)) {
							return compareStringInArray(value, compare);
						}
					}
					return false;
				});
			});
		}
		return array;
	}
}

// @Pipe({ name: 'minDate' })
// // | minDate:key:value
// // key = la clé de l'objet qui définit la date minimum
// // value est la valeur de comparaison
// // Si object[key] <= minDate, on l'enleve de l'array
// export class minDate implements PipeTransform {
//   transform(array: any[], key: string, minDate: any): any[] {
//     if (array && array.length > 0) {
//       // Si on a pas un array valable, alors ca ne vaut pas la peine
//       var mindate: any = moment(minDate);
//       if (!key) {
//         throw 'Il faut une key => minDate:key:value';
//       } else if (!mindate._isValid) {
//         throw "minDate n'est pas valide !!!";
//       } else {
//         return array.filter((item: any) => {
//           var date: any = moment(item[key]);
//           return date._isValid && mindate <= date;
//         });
//       }
//     } else {
//       return array;
//     }
//   }
// }

// @Pipe({ name: 'maxDate' })
// // | maxDate:key:value
// // key = la clé de l'objet qui définit la date minimum
// // value est la valeur de comparaison
// // Si object[key] >= maxDate, on l'enleve de l'array
// export class maxDate implements PipeTransform {
//   transform(array: any[], key: string, maxDate: any): any[] {
//     if (array && array.length > 0) {
//       // Si on a pas un array valable, alors ca ne vaut pas la peine
//       var maxdate: any = moment(maxDate);
//       if (!key) {
//         throw 'Il faut une key => maxDate:key:value';
//       } else if (!maxdate._isValid) {
//         throw "maxDate n'est pas valide !!!";
//       } else {
//         return array.filter((item: any) => {
//           var date: any = moment(item[key]);
//           return date._isValid && maxdate >= date;
//         });
//       }
//     } else {
//       return array;
//     }
//   }
// }

@Pipe({ name: 'find' })
export class findArrayFilter implements PipeTransform {
	/**
	 * 
	 * @param array 
	 * @param key 
	 * @param value 
	 * @returns 
	 */
	transform(array: any[], key: string, value: any): any {
		if (Array.isArray(array) && array.length > 0) {
			return array.find((x) => x[key] === value);
		}
		return array;
	}
}
