<div class="container" [formGroup]="form">
  <div class="row">
    <div class="col-lg-12">
      On mettra ici tout ce qui est en rapport avec la clinique sélectionnée
    </div>
  </div>
  <h3>Votre animal</h3>
  <div class="row">
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="animalName" class="d-block" translate>Nom</label>
      <input
        id="animalName"
        class="w-100"
        type="text"
        pInputText
        formControlName="animalName"
      />
    </div>
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="animalType" class="d-block" translate>Type</label>
      <input
        id="animalType"
        class="w-100"
        type="text"
        pInputText
        formControlName="animalType"
      />
    </div>
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="animalAge" class="d-block" translate>Age</label>
      <p-inputNumber inputId="integeronly" formControlName="animalAge">
      </p-inputNumber>
    </div>
  </div>
  <h3>Vous</h3>
  <div class="row">
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="lastName" class="d-block" translate>Votre nom</label>
      <input
        id="lastName"
        class="w-100"
        type="text"
        pInputText
        formControlName="lastName"
      />
    </div>
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="firstName" class="d-block" translate>Votre prénom</label>
      <input
        id="firstName"
        class="w-100"
        type="text"
        pInputText
        formControlName="firstName"
      />
    </div>
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="email" class="d-block" translate>Votre email</label>
      <input
        id="email"
        class="w-100"
        type="email"
        pInputText
        formControlName="email"
      />
    </div>
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="phone" class="d-block" translate
        >Votre numéro de téléphone</label
      >
      <div class="d-flex justify-content-start">
        <p-dropdown
          [options]="prefixes"
          formControlName="prefix"
          placeholder="Select a prefix"
        ></p-dropdown>
        <p-inputMask
          mask="999/99.99.99"
          id="phone"
          formControlName="phone"
          placeholder="470/12.34.56"
        ></p-inputMask>
      </div>
    </div>
  </div>
  <h3>Votre message</h3>
  <div class="row">
    <div class="col-lg-12 mb-3 mb-lg-0">
      <textarea
        id="message"
        [style]="{ width: '100%' }"
        pInputTextarea
        [autoResize]="true"
        formControlName="message"
      ></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 mb-3 mb-lg-0">
      <label for="animalType" class="d-block" translate>Pièces jointes</label>
      <p-fileUpload
        name="myfile[]"
        url="https://www.primefaces.org/cdn/api/upload.php"
        [multiple]="true"
        accept="image/*"
        [maxFileSize]="12e6"
      >
        <ng-template pTemplate="content">
          <ul *ngIf="uploadedFiles.length > 0; else noFile">
            <li *ngFor="let file of uploadedFiles">
              {{ file.name }} - {{ file.size }} bytes
            </li>
          </ul>
        </ng-template>
        <ng-template #noFile
          >Glissez/Déposez des fichiers dans cette zone. Par exemple les radios
          de votre animal ou son passeport vétérinaire.</ng-template
        >
      </p-fileUpload>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 mb-3 mb-lg-0">
      <button class="btn btn-primary w-100" pButton translate>
        Envoyer la demande de rendez-vous
      </button>
    </div>
  </div>
</div>
