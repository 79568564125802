import { Component } from '@angular/core';
import { OlgaService } from 'src/app/services/olga.service';
import { SearchFormService } from 'src/app/services/search-form.service';

@Component({
  selector: 'olga-search-clinics-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class SidebarFiltersComponent {
  constructor(
    public olga: OlgaService,
    public searchForm: SearchFormService,
  ) {

  }

  get range(): number[] {
    const control = this.searchForm.form.controls["localizationWithin"]
    return control.value || [];
  }

}
