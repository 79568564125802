export const days: daysType[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saterday",
    "Sunday",
];

export type daysType = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saterday" | "Sunday";