import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { OlgaPrimengModule } from './primeng.module';
import { OlgaTranslateModule } from './translate.module';

import { HeaderComponent } from '../components/header/header.component';
import { FooterComponent } from '../components/footer/footer.component';
import { FormSearchComponent } from '../components/form-search/form-search.component';
import { PageHomeComponent } from '../components/page-home/page-home.component';
import { PageSearchComponent } from '../components/page-search/page-search.component';
import { HomeHeroComponent } from '../components/home-hero/home-hero.component';
import { CardClinicComponent } from '../components/card-clinic/card-clinic.component';
import { SidebarFiltersComponent } from '../components/filters/filters.component';
import { JoinUsComponent } from '../components/page-join-us/join-us.component';
import { MySpaceComponent } from '../components/page-my-space/my-space.component';
import { AppointmentComponent } from '../components/page-appointment/appointment.component';
import { PipesModule } from '../pipes/pipes.module';
import { LoaderComponent } from '../components/loader/loader.component';
import { PageClinicComponent } from '../components/page-clinic/page-clinic.component';
import { NgLetModule } from 'ng-let';

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		FormSearchComponent,
		PageHomeComponent,
		PageSearchComponent,
		HomeHeroComponent,
		CardClinicComponent,
		SidebarFiltersComponent,
		JoinUsComponent,
		AppointmentComponent,
		MySpaceComponent,
		LoaderComponent,
		PageClinicComponent,
	],
	providers: [DialogService],
	exports: [
		HeaderComponent,
		FooterComponent,
	],
	imports: [
		CommonModule,
		OlgaPrimengModule,
		ReactiveFormsModule,
		OlgaTranslateModule,
		PipesModule,
		NgLetModule,
	]
})
export class OlgaComponentsModule { }
