<div class="container" [formGroup]="form">
  <h2>Vous voulez rejoindre notre plateforme ?</h2>
  <h3>Vos coordonnées</h3>
  <div class="row">
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="lastName" class="d-block" translate>Votre nom</label>
      <input
        id="lastName"
        class="w-100"
        type="text"
        pInputText
        formControlName="lastName"
      />
    </div>
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="firstName" class="d-block" translate>Votre prénom</label>
      <input
        id="firstName"
        class="w-100"
        type="text"
        pInputText
        formControlName="firstName"
      />
    </div>
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="email" class="d-block" translate>Votre email</label>
      <input
        id="email"
        class="w-100"
        type="email"
        pInputText
        formControlName="email"
      />
    </div>
    <div class="col-lg-6 mb-3 mb-lg-0">
      <label for="phone" class="d-block" translate
        >Votre numéro de téléphone</label
      >
      <div class="d-flex justify-content-start">
        <p-dropdown
          [options]="prefixes"
          formControlName="prefix"
          placeholder="Select a prefix"
        ></p-dropdown>
        <p-inputMask
          mask="999/99.99.99"
          id="phone"
          formControlName="phone"
          placeholder="470/12.34.56"
        ></p-inputMask>
      </div>
    </div>
  </div>
  <h3>Votre message</h3>
  <div class="row">
    <div class="col-lg-12 mb-3 mb-lg-0">
      <label for="message" translate>Votre message</label>
      <textarea
        id="message"
        [rows]="5"
        [style]="{ width: '100%' }"
        pInputTextarea
        [autoResize]="true"
        formControlName="message"
      ></textarea>
    </div>
  </div>
  <div
    *ngIf="result.message && result.message.length > 0"
    class="row"
    [innerHTML]="result.message"
  ></div>
  <div class="row">
    <div class="col-lg-12 mb-3 mb-lg-0">
      <button (click)="send()" class="btn btn-primary w-100" pButton translate>
        Envoyez votre demande
      </button>
    </div>
  </div>
</div>
