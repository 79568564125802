import { Subject } from 'rxjs';

class Options {
    public interval?: number
    public class?: any
    public key?: string
}

export class SuperObserveList {

    private key: string | "id" = "id";
    public items = new Map();
    private items$ = new Subject();
    public hasChanged = new Subject();

    constructor(options?: Options) {

        const keyLength = options?.key?.length || 0;
        if (keyLength === 1 && options?.key) {
            this.key = options.key;
        }

        this.items$.subscribe((x: any) => {
            if (x.flag && x.flag === "deleted") {
                this.items.delete(x[this.key])
            }
            else {
                if (options?.class) {
                    // const className = options.class
                    this.items.set(x[this.key], new options.class(x));
                }
                else {
                    this.items.set(x[this.key], x);
                }
            }
            this.hasChanged.next(true);
        })
        return this;
    }

    public get(id: string) {
        return this.items.get(id);
    }

    public get length() {
        return this.items.size;
    }

    public setKey(key: string) {
        this.key = key;
        return this;
    }

    public reset() {
        this.items = new Map();
        return this;
    }

    /// Add an object to list. 
    public push(x: any) {
        if (Array.isArray(x)) {
            x.map(_x => {
                this.items$.next(_x)
            })
        }
        else {
            this.items$.next(x)
        }
    }

    public remove(x: any) {
        if (Array.isArray(x)) {
            x.map(_x => {
                _x.flag = "deleted"
                this.items$.next(_x)
            })
        }
        else {
            x.flag = "deleted"
            this.items$.next(x)
        }
    }

}

