import {
	HttpClient,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SuperObserveList } from '../utils/observeList';
import { NotificationsService } from './notifications.service';
import { AppInjector } from '../app.module';
import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';

interface CRUD {
	id?: number;
}

RequestQueryBuilder.setOptions({
	delim: '||',
	delimStr: ',',
	paramNamesMap: {
		fields: ['fields', 'select'],
		search: 's',
		filter: ['filter[]', 'filter'],
		or: ['or[]', 'or'],
		join: ['join[]', 'join'],
		sort: ['sort[]', 'sort'],
		limit: ['per_page', 'limit'],
		offset: ['offset'],
		page: ['page'],
		cache: ['cache'],
	},
});

export class CrudService<T extends CRUD> {
	tableName: string;
	running = false;
	notification: NotificationsService;

	public datas = new SuperObserveList({
		class: this.model,
		key: 'id',
	});

	constructor(protected http: HttpClient, public model: any) {
		this.tableName = new this.model().tableName;
		this.notification = AppInjector.get(NotificationsService);
	}

	toQueryString(options: CreateQueryParams = {}) {
		const queryString = RequestQueryBuilder.create(options).query();
		return queryString;
	}

	error(error: HttpErrorResponse) {
		console.error(`Error come from ${this.tableName} CRUD Service`);
		if (error.message) {
			this.notification.error({
				detail: error.message,
			});
		}
		console.log(error);
	}

	get(options: CreateQueryParams = {}): Observable<T[]> {
		return this.http.get<T[]>(
			`${environment.API_URL}/${this.tableName}?${this.toQueryString(options)}`
		);
	}

	async load(options: CreateQueryParams = {}): Promise<T[]> {
		this.running = true;
		const items = await lastValueFrom(this.get(options)).then((resp) =>
			resp.map((x) => new this.model(x))
		);
		this.datas.push(items);
		this.running = false;
		return items;
	}

	getByID(id: number): Observable<T> {
		return this.http
			.get<T>(`${environment.API_URL}/${this.tableName}/${id}`)
			.pipe(take(1));
	}

	async loadById(id: number): Promise<T> {
		this.running = true;
		const item = await lastValueFrom(this.getByID(id));
		this.datas.push(item);
		this.running = false;
		return item;
	}

	createRecord(record: T) {
		return this.http
			.post(`${environment.API_URL}/${this.tableName}`, record)
			.pipe(take(1));
	}

	create(record: T) {
		this.running = true;
		return lastValueFrom(this.createRecord(record))
			.then((r) => {
				this.datas.push(r);
				return r;
			})
			.catch((error) => {
				this.error(error);
			})
			.finally(() => {
				this.running = false;
			});
	}

	patchRecord(record: T) {
		return this.http
			.patch(`${environment.API_URL}/${this.tableName}/${record['id']}`, record)
			.pipe(take(1));
	}

	patch(record: T) {
		this.running = true;
		return lastValueFrom(this.patchRecord(record))
			.then((r) => {
				this.datas.push(r);
				return r;
			})
			.catch((error) => {
				this.error(error);
			})
			.finally(() => {
				this.running = false;
			});
	}

	save(record: T) {
		if (record['id'] && record['id'] >= 0) {
			return this.patch(record);
		}
		return this.create(record);
	}

	remove(id: number) {
		return this.http
			.delete(`${environment.API_URL}/${this.tableName}/${id}`)
			.pipe(take(1));
	}
}
