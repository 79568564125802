<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <form-search></form-search>
    </div>
  </div>
</div>
<div class="olga_search_wrapper bg_grey_light">
  <div class="container">
    <div class="col-lg-12">
      <div class="row">
        <div *ngIf="!searchForm.isLoading; else loading" class="col-lg-12 pt-2">
          <div
            class="col-lg-12 d-block d-lg-flex justify-content-between align-items-center py-2"
          >
            <p class="lead mb-lg-0">
              <span translate>Nous avons trouvé </span>
              <strong class="fw-bold">
                {{ searchForm.clinics.length }}
                <span translate> clinique</span>
                <span *ngIf="searchForm.clinics.length > 1">s</span>
              </strong>
              <span translate> correspondant à votre recherche</span>
            </p>
            <button
              class="btn btn-secondary btn-outline-primary"
              pButton
              (click)="sidebarVisible = true"
            >
              <i class="pi pi-filter me-1" style="color: #222222"></i>Filtres
            </button>
          </div>
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            <div class="col" *ngFor="let clinic of searchForm.clinics">
              <card-clinic [data]="clinic"></card-clinic>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <olga-loader></olga-loader>
</ng-template>

<p-sidebar [(visible)]="sidebarVisible" [blockScroll]="true" position="right">
  <olga-search-clinics-filters></olga-search-clinics-filters>
</p-sidebar>
