import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsModule } from 'primeng/steps';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { ChipsModule } from 'primeng/chips';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    StepsModule,
    DropdownModule,
    InputTextModule,
    AutoCompleteModule,
    InputMaskModule,
    InputNumberModule,
    ChipsModule,
    InputTextareaModule,
    MultiSelectModule,
    CalendarModule,
    SliderModule,
    DynamicDialogModule,
    ToastModule,
    CardModule,
    FileUploadModule,
    GalleriaModule,
    SidebarModule,
    DialogModule,
  ],
  providers: [
    MessageService,
  ],
  exports: [
    StepsModule,
    DropdownModule,
    InputTextModule,
    AutoCompleteModule,
    InputMaskModule,
    InputNumberModule,
    ChipsModule,
    InputTextareaModule,
    MultiSelectModule,
    CalendarModule,
    SliderModule,
    DynamicDialogModule,
    ToastModule,
    CardModule,
    FileUploadModule,
    GalleriaModule,
    SidebarModule,
    DialogModule,
  ]
})
export class OlgaPrimengModule { }
