import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { lastValueFrom } from 'rxjs';
import { prefixes } from 'src/app/enums/prefixes';
import { OlgaService } from 'src/app/services/olga.service';

@Component({
  selector: 'join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent {
  prefixes = prefixes;
  constructor(
    public olga: OlgaService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {

  }
  result = {
    message: "",
  };
  form = new FormGroup({
    firstName: new FormControl('', {
      updateOn: 'blur',
      validators: [Validators.required, Validators.minLength(4)],
    }),
    lastName: new FormControl('', {
      updateOn: 'blur',
      validators: [Validators.required, Validators.minLength(4)],
    }),
    phone: new FormControl('', {
      updateOn: 'blur',
      validators: [Validators.required, Validators.minLength(4)],
    }),
    prefix: new FormControl(32, {
      updateOn: 'blur',
      validators: [Validators.required, Validators.minLength(2)],
    }),
    email: new FormControl('', {
      updateOn: 'blur',
      validators: [Validators.email, Validators.required, Validators.email],
    }),
    message: new FormControl('', {
      updateOn: 'blur',
      validators: [],
    }),
  });

  async send() {
    const token = await lastValueFrom(this.recaptchaV3Service.execute('importantAction'))
    this.result = await this.olga.sendJoinUs(this.form);
  }

}
