import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { User } from '../entities/user';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UserService extends CrudService<User> {
	public connected = false;
	public data: User = new User();
	constructor(
		protected override http: HttpClient,
		private auth: AuthService,
	) {
		super(http, User);
	}

	get isConnected() {
		return this.auth.isAuthenticated$;
	}

	public login() {
		return this.auth.loginWithRedirect();
	}

	public logout() {
		return this.auth.logout({ logoutParams: { returnTo: environment.BASE_URL } });
	}
}
